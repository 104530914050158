import React, { useEffect, useContext } from 'react'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import { ClassNames } from 'services/classname.service'
import { NavStateContext } from 'providers/nav'

import Container from 'containers/Container'
import styles from './Masthead.module.scss'
import Nav from 'features/Nav'
import Header from 'features/Header'
import Spacer from 'basics/Spacer'
import Button from 'basics/Button'
import Hamburger from 'basics/Hamburger'

const Masthead = ({
  topLinks = [],
  mainLinks = [],
  route = {},
  ctaText = '',
  ctaLink = '',
  type
}) => {
  const router = useRouter()
  const { slug } = route || {}
  const { navOpen, closeNav, toggleMenu } = useContext(NavStateContext) || {}

  useEffect(() => {
    const handleRouteChange = (url, { shallow }) => {
      closeNav()
      toggleMenu(-1)
    }

    router.events.on('routeChangeStart', handleRouteChange)

    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [])

  if (type === 'simple') {
    return (
      <header
        id="fbbMasthead" 
        className={ClassNames([styles['masthead'], styles['masthead--simple']])}
        open={navOpen}
      >
        <Container>
          <div className={ClassNames([styles['masthead__header'], 'px-4 pt-3'])}>
            <div className={styles['logo']}>
              <a href="/">
                <picture>
                  {/* <source srcSet={'/logo.svg'} /> */}
                  <img src="/logo.svg" width="128" alt="First Business Bank" />
                </picture>
              </a>
            </div>
          </div>
        </Container>
      </header>
    )
  }

  return (
    <>
      <div id="fbbMasthead" className={ClassNames([styles['masthead']])} open={navOpen}>
        <div
          className={ClassNames([
            styles['masthead__header'],
            'display-none md-display-block px-4 pt-3'
          ])}
        >
          <div className={styles['logo']}>
            <a href="/">
              <picture>
                {/* <source srcSet={'/logo.svg'} /> */}
                <img src="/logo.svg" width="128" alt="First Business Bank" />
              </picture>
            </a>
          </div>
          <Hamburger className={styles['ds-hamburger']} />
        </div>
        <div className={ClassNames([styles['masthead__background'], 'md-mt-6'])}>
          <div className={ClassNames([styles['masthead__contents']])}>
            <Header
              className={ClassNames([styles['header-container']])}
              navItems={mainLinks}
              route={slug}
              open={navOpen}
            />
            <Nav
              className={ClassNames([styles['util-nav-container']])}
              navLinks={topLinks || []}
              condensed={true}
              route={slug}
            />
            <div className={styles['cta']}>
              <Button url="/contact-us">Contact Us</Button>
            </div>
            <Spacer size={5} />
          </div>
        </div>
      </div>
    </>
  )
}

Masthead.propTypes = {
  cta: PropTypes.any
}

export default Masthead
